.timeline__container {
    margin-top: 5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    .timeline-item {
        position: relative;
        padding-left: 3rem;
        border-left: 1px solid var(--color-light);
        .timeline-icon {
            position: absolute;
            left: -6px;
            top: 0;
            background-color: var(--color-primary);
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .timeline-duration {
            padding: .2rem .6rem;
            background-color: var(--color-grey-1);
            border-radius: 15px;
            display: inline-block;
            font-size: .8rem;
            text-transform: uppercase;
            font-weight: 500;
        }
        p {
            color: var(--color-light);
        } 
    }
}
/*============MEDIA QUERIES (MEDIUM DEVICES - TABLETS)==========*/
@media screen and (max-width: 1024px) {
    .timeline__container {
        padding-left: 5rem;
    }
}

/*============MEDIA QUERIES (SMALL DEVICES - PHONES)==========*/
@media screen and (max-width: 600px) {
    .timeline__container {
        padding-left: 2rem;
    }
}