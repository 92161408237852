.timeline__container {
  margin-top: 5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}
.timeline__container .timeline-item {
  position: relative;
  padding-left: 3rem;
  border-left: 1px solid var(--color-light);
}
.timeline__container .timeline-item .timeline-icon {
  position: absolute;
  left: -6px;
  top: 0;
  background-color: var(--color-primary);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeline__container .timeline-item .timeline-duration {
  padding: 0.2rem 0.6rem;
  background-color: var(--color-grey-1);
  border-radius: 15px;
  display: inline-block;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 500;
}
.timeline__container .timeline-item p {
  color: var(--color-light);
}

/*============MEDIA QUERIES (MEDIUM DEVICES - TABLETS)==========*/
@media screen and (max-width: 1024px) {
  .timeline__container {
    padding-left: 5rem;
  }
}
/*============MEDIA QUERIES (SMALL DEVICES - PHONES)==========*/
@media screen and (max-width: 600px) {
  .timeline__container {
    padding-left: 2rem;
  }
}/*# sourceMappingURL=timeline.css.map */