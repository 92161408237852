header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/*============ActionBtn============*/
.action-btn {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}


/*============Scrolling============*/
.scroll__down {
    position: absolute;
    color: var(--color-white);
    right: -2.3rem;
    bottom: 4rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

.image {
    margin-top: 1rem;
    height: 100%;
    display: block;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.content span.img-text {
    text-transform: uppercase;
    display: inline-block;
    top: 50%;
    transform: translateY(50%);
    padding: 1rem;
    font-size: 1.5rem;
    letter-spacing: 10px;
    text-align: center;
}

/*============MEDIA QUERIES (MEDIUM DEVICES - TABLETS)==========*/
@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }
}

/*============MEDIA QUERIES (SMALL DEVICES - PHONES)==========*/
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header__socials,
    .scroll__down {
        display: none;
    }
}

